<template>
  <div class="attribute-checkbox">
    <el-checkbox :value="flag == 1"></el-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    flag: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="less">
.attribute-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;

  .el-checkbox__inner {
    // color: red;
    cursor: not-allowed;
  }
}
</style>
