<template>
  <el-drawer
    :title="`添加藏品-${attributeData.managent_group_name}`"
    :visible="visible"
    direction="rtl"
    size="75%"
    @close="onClose"
  >
    <div class="add-nft-container">
      <div>
        <el-form :inline="true" :model="queryForm">
          <el-form-item item label="藏品名称">
            <el-input v-model="queryForm.nft_name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item item label="藏品TXID">
            <el-input v-model="queryForm.txid" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item item label="分类">
            <el-select v-model="queryForm.category_id" placeholder="请选择">
              <el-option
                v-for="item in categoryList"
                :key="item.id"
                :label="item.title_cn"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onQuery">查询</el-button>
            <el-button @click="onClear">重置</el-button>
          </el-form-item>
        </el-form>

        <el-table
          :data="list"
          border
          stripe
          row-key="id"
          height="calc(100vh - 260px)"
          class="my-table"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="藏品名称" prop="nft_name" align="center"> </el-table-column>
          <el-table-column label="预览图" prop="album_count" align="center">
            <template slot-scope="scope">
              <AttributeNFTImage :cover="scope.row.cover" />
            </template>
          </el-table-column>

          <el-table-column label="txid" prop="txid" align="center">
            <template slot-scope="scope">
              <CopyTxid :txid="scope.row.txid" />
            </template>
          </el-table-column>
          <el-table-column label="铸造者" prop="creator_name" align="center"> </el-table-column>
          <el-table-column label="分类" prop="category_name" align="center"> </el-table-column>
        </el-table>

        <div style="margin-top: 20px; text-align: right;">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>

      <div class="add-nft-footer">
        <div style="font-weight: 600; margin-left: 20px;">已选：{{ multipleSelection.length }}</div>
        <div style="margin-right: 20px">
          <el-button @click="onClose">取消</el-button>
          <el-button
            type="primary"
            :disabled="multipleSelection.length === 0"
            @click="onConfirmAdd"
          >
            确定
          </el-button>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { addNFTsToGroup, getNFTList } from "@/api/nft-attribute.js";
import { getNFTCategoryList } from "@/api/nft.js";
import AttributeNFTImage from "@/components/AttributeNFTImage.vue";
import CopyTxid from "@/components/CopyTxid.vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // attributeData: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  components: {
    CopyTxid,
    AttributeNFTImage,
  },
  data() {
    return {
      queryForm: {},
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      categoryList: [],
      multipleSelection: [],
      attributeData: {},
    };
  },
  mounted() {},
  methods: {
    init(data) {
      if (data) {
        this.attributeData = data;
        this.fetchNFTList();
        this.fetchCategoryList();
      }
    },
    getPatamsFromQueryForm() {
      let params = {};
      Object.keys(this.queryForm).forEach((key) => {
        if (this.queryForm[key]) {
          params[key] = this.queryForm[key];
        }
      });
      return params;
    },
    async fetchCategoryList() {
      try {
        const { data } = await getNFTCategoryList({
          page: 1,
          page_size: 1000,
        });
        this.categoryList = data.data.items;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchNFTList() {
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
          managent_group_ids: ["default"],
          ...this.getPatamsFromQueryForm(),
        };
        const { data } = await getNFTList(params);
        if (data?.data) {
          this.list = data.data.items || [];
          this.total = data.data.total || 0;
        }
      } catch (error) {}
    },
    async onConfirmAdd() {
      try {
        const params = {
          txids: this.multipleSelection.map((i) => i.txid),
          managent_group_id: this.attributeData.managent_group_id,
        };
        await addNFTsToGroup(params);
        this.$message.success("新增成功");
        this.$emit("success");
      } catch (error) {
        console.log(error);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onClose() {
      console.log(1);
      this.$emit("close");
    },
    onQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchNFTList();
    },
    onClear() {
      this.queryForm = {};
      this.page = 1;
      this.pageSize = 20;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchNFTList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchNFTList();
    },
  },
};
</script>

<style lang="less" scoped>
.add-nft-container {
  position: relative;
  padding: 0 20px 60px 20px;
  height: 100%;

  .add-nft-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ddd;
  }
}
</style>
