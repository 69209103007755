<template>
  <el-image
    :src="useResize ? `${cover}?x-oss-process=image/resize,w_120` : cover"
    fit="fill"
    :preview-src-list="[cover]"
    style="width: 100px; height: 100px; object-fit: cover;"
    @error="onError"
  ></el-image>
</template>

<script>
export default {
  data() {
    return {
      useResize: true,
    };
  },
  methods: {
    onError() {
      this.useResize = false;
    },
  },
  props: {
    cover: {
      type: String,
      default: "",
    },
  },
};
</script>
