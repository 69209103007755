<template>
  <div>
    <el-drawer
      :title="`${attributeData.managent_group_name} (${attributeData.album_count})`"
      :visible="visible"
      direction="rtl"
      size="75%"
      @close="onClose"
    >
      <div class="add-nft-container">
        <div>
          <el-form :inline="true" :model="queryForm">
            <el-form-item item label="藏品名称">
              <el-input v-model="queryForm.nft_name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item item label="藏品TXID">
              <el-input v-model="queryForm.txid" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item item label="积分是否可兑换" v-if="isAllGroup">
              <el-select v-model="queryForm.exchangeable" placeholder="请选择">
                <el-option
                  v-for="item in exchangeableOptions"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item item label="兑换条件" v-if="isAllGroup">
              <el-select v-model="queryForm.exchange_snapshot_type" placeholder="请选择">
                <el-option
                  v-for="item in pointOptionList"
                  :key="item.key"
                  :label="item.name"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item item label="分类">
              <el-select v-model="queryForm.category_id" placeholder="请选择">
                <el-option
                  v-for="item in categoryList"
                  :key="item.id"
                  :label="item.title_cn"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onQuery">查询</el-button>
              <el-button @click="onClear">重置</el-button>
            </el-form-item>
          </el-form>

          <el-table
            :data="list"
            border
            stripe
            row-key="id"
            height="calc(100vh - 240px)"
            class="my-table"
          >
            <el-table-column label="藏品名称" prop="nft_name" align="center"> </el-table-column>
            <el-table-column label="预览图" prop="album_count" align="center">
              <template slot-scope="scope">
                <el-image
                  :src="`${scope.row.cover}?x-oss-process=image/resize,w_100`"
                  fit="fill"
                  :preview-src-list="[scope.row.cover]"
                  style="width: 100px; height: 100px; object-fit: cover;"
                ></el-image>
              </template>
            </el-table-column>

            <el-table-column label="txid" prop="txid" align="center">
              <template slot-scope="scope">
                <CopyTxid :txid="scope.row.txid" />
              </template>
            </el-table-column>
            <el-table-column label="铸造者" prop="creator_name" align="center"> </el-table-column>
            <el-table-column label="分类" prop="category_name" align="center"> </el-table-column>
            <el-table-column label="上架状态" prop="is_on_shelf" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.is_on_shelf ? "已上架" : "未上架" }}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="积分兑换值"
              prop="exchange_nft_info.exchange_need_points"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="积分兑换条件"
              prop="exchange_nft_info.exchange_snapshot_type"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  {{ getPointOptionName(scope.row.exchange_nft_info?.exchange_snapshot_type) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="250" align="center">
              <template slot-scope="scope">
                <el-button
                  v-if="+attributeData.deletable === 1"
                  size="mini"
                  type="danger"
                  @click="handleClickRemove(scope.row)"
                >
                  删除
                </el-button>
                <el-button size="mini" type="primary" @click="handleClickDetail(scope.row)">
                  编辑
                </el-button>
                <el-button
                  size="mini"
                  :disabled="scope.row.exchange_nft_info == null"
                  type="警告按钮"
                  @click="handleClickRevoke(scope.row)"
                >
                  撤销积分兑换
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <div style="margin-top: 20px; text-align: right;">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-drawer>
    <PointMallEditNFTDialog
      :visible="pointMallEditVisible"
      @close="handleDetailClose"
      @success="handleEditPointSuccess"
      ref="pointMallEditDetailDialogRef"
    />
  </div>
</template>

<script>
import {
  getNFTList,
  removeNFTFromGroup,
  getExchangeSnapshotTypeList,
  cancelExchangeInfo,
} from "@/api/nft-attribute.js";
import { getNFTCategoryList } from "@/api/nft.js";
import CopyTxid from "@/components/CopyTxid.vue";
import PointMallEditNFTDialog from "@/components/PointMallEditNFTDialog.vue";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    /// 是否是"所有藏品"分类
    isAllGroup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CopyTxid,
    PointMallEditNFTDialog,
  },
  data() {
    return {
      pointMallEditVisible: false,
      queryForm: {},
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      categoryList: [],
      attributeData: {},
      currentItem: {},
      pointOptionList: [],
      exchangeableOptions: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
    };
  },
  methods: {
    init(data) {
      this.attributeData = data;
      this.queryForm = {};
      this.fetchNFTList();
      this.fetchCategoryList();
      this.fetchOptionsList();
    },
    async fetchCategoryList() {
      try {
        const { data } = await getNFTCategoryList({
          page: 1,
          page_size: 1000,
        });
        this.categoryList = data.data.items;
      } catch (error) {
        console.log(error);
      }
    },
    getPatamsFromQueryForm() {
      let params = {};
      Object.keys(this.queryForm).forEach((key) => {
        if (this.queryForm[key] !== "" && this.queryForm[key] !== null) {
          params[key] = this.queryForm[key];
        }
      });
      return params;
    },
    async fetchNFTList() {
      try {
        const queryParams = this.getPatamsFromQueryForm();
        const params = {
          page: this.page,
          page_size: this.pageSize,
          managent_group_ids: [this.attributeData.managent_group_id],
          ...queryParams,
        };
        const { data } = await getNFTList(params);
        if (data?.data) {
          this.list = data.data.items || [];
          this.total = data.data.total || 0;
        }
      } catch (error) {}
    },
    async fetchOptionsList() {
      try {
        const { data } = await getExchangeSnapshotTypeList();
        this.pointOptionList = data.data.list;
      } catch (error) {
        console.log(error);
      }
    },
    getPointOptionName(type) {
      const item = this.pointOptionList.find((item) => item.key == type);
      return item?.name ?? "";
    },
    handleDetailClose() {
      this.pointMallEditVisible = false;
    },
    handleEditPointSuccess() {
      this.fetchNFTList();
      this.pointMallEditVisible = false;
    },
    handleClickDetail(item) {
      this.currentItem = item;
      this.$refs.pointMallEditDetailDialogRef.init(item);
      this.pointMallEditVisible = true;
    },
    handleClickRemove(item) {
      this.$confirm(`是否确认删除【${item.nft_name}】?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDelete(item);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async handleDelete(item) {
      try {
        const postData = {
          txid: item.txid,
        };
        await removeNFTFromGroup(postData);
        this.$message.success("删除成功");
        this.fetchNFTList();
      } catch (error) {
        console.log(error);
      }
    },
    handleClickRevoke(item) {
      this.$confirm(`撤销积分兑换后，所填信息将清空，是否清空`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleRevoke(item);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async handleRevoke(item) {
      try {
        const postData = {
          txid: item.txid,
        };
        await cancelExchangeInfo(postData);
        this.$message.success("操作成功");
        this.fetchNFTList();
      } catch (error) {
        console.log(error);
      }
    },
    onClose() {
      this.$emit("close");
    },
    onQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchNFTList();
    },
    onClear() {
      this.queryForm = {};
      this.page = 1;
      this.pageSize = 20;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchNFTList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchNFTList();
    },
  },
};
</script>

<style lang="less" scoped>
.add-nft-container {
  position: relative;
  padding: 0 20px 60px 20px;
  height: 100%;

  .add-nft-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ddd;
  }
}
</style>
