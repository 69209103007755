<template>
  <div>
    <el-form style="margin: 20px 0;" :inline="true" :model="queryForm">
      <el-form-item item label="藏品名称">
        <el-input v-model="queryForm.nft_name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item item label="权益值加成">
        <el-input v-model="queryForm.equity_value" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button @click="onClear">重置</el-button>
        <el-button type="success" @click="onAdd">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="list" border stripe row-key="id">
      <el-table-column label="藏品名称" prop="nft_name" align="center"> </el-table-column>
      <el-table-column label="藏品TXID" prop="txid" align="center"> </el-table-column>
      <el-table-column label="权益值加成" prop="equity_value" align="center"> </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleClickUpdate(scope.row)"
            style="margin: 4px"
          >
            修改
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleClickDelete(scope.row)"
            style="margin: 4px"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px; text-align: right;">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog title="新增" :visible.sync="addVisible" width="30%" @close="addVisible = false">
      <el-form
        class="add-rights-form"
        :model="addForm"
        ref="addForm"
        :rules="addRules"
        label-position="top"
        size="normal"
      >
        <el-form-item label="藏品类型">
          <el-input value="权益值加成" disabled></el-input>
        </el-form-item>
        <el-form-item label="藏品TXID" prop="txid">
          <el-input v-model="addForm.txid">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="onQueryNFT(addForm.txid)"
            ></el-button>
          </el-input>
          <div v-if="nftData">藏品名称：{{ nftData.name }} 数量：{{ nftData.total }}</div>
        </el-form-item>
        <el-form-item label="权益值加成" prop="equity_value">
          <el-input v-model="addForm.equity_value"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onConfirmAdd">确定</el-button>
          <el-button @click="addVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="修改"
      :visible.sync="updateVisible"
      width="30%"
      @close="updateVisible = false"
    >
      <el-form
        class="add-rights-form"
        :model="updateForm"
        ref="updateForm"
        :rules="addRules"
        label-position="top"
        size="normal"
      >
        <el-form-item label="藏品类型">
          <el-input value="权益值加成" disabled></el-input>
        </el-form-item>
        <el-form-item label="藏品TXID" prop="txid">
          <el-input v-model="updateForm.txid" disabled> </el-input>
          <div v-if="nftData">藏品名称：{{ nftData.name }} 数量：{{ nftData.total }}</div>
        </el-form-item>
        <el-form-item label="权益值加成" prop="equity_value">
          <el-input v-model="updateForm.equity_value"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onConfirmUpdate">确定</el-button>
          <el-button @click="updateVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getNFTInfoByTxid, getRightsList, setNFTRights } from "@/api/nft-attribute.js";

export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],

      queryForm: {},

      // 新增组
      addVisible: false,
      addForm: {},
      addRules: {
        equity_value: [
          {
            required: true,
            message: "请输入权益值",
            trigger: "change",
          },
        ],
        txid: [
          {
            required: true,
            message: "请输入藏品TXID",
            trigger: "blur",
          },
        ],
      },
      // 编辑组
      updateVisible: false,
      updateForm: {},
      updateRules: {
        equity_value: [
          {
            required: true,
            message: "请输入权益值",
            trigger: "change",
          },
        ],
      },

      nftData: null,
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    getPatamsFromQueryForm() {
      let params = {};
      Object.keys(this.queryForm).forEach((key) => {
        if (this.queryForm[key]) {
          params[key] = this.queryForm[key];
        }
      });
      return params;
    },
    async fetchList() {
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
          ...this.getPatamsFromQueryForm(),
        };
        // string 转 int
        if (params.equity_value) {
          params.equity_value = +params.equity_value;
        }
        const { data } = await getRightsList(params);
        if (data && data.data) {
          this.list = data.data.items;
          this.total = data.data.total || 0;
        }
      } catch (error) {}
    },

    handleClickUpdate(item) {
      this.updateForm = {
        txid: item.txid,
        equity_value: +item.equity_value,
      };
      this.nftData = {
        name: item.nft_name,
        total: "",
      };
      this.onQueryNFT(item.txid);
      this.updateVisible = true;
    },
    handleClickDelete(item) {
      this.$confirm(`是否确认删除【${item.nft_name}】?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDelete(item);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async handleDelete(item) {
      try {
        const params = {
          txid: item.txid,
          equity_value: 0,
        };
        await setNFTRights(params);
        this.$message.success("删除成功");
        this.fetchList();
      } catch (error) {
        console.log(error);
      }
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchList();
    },
    onQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchList();
    },
    onClear() {
      this.queryForm = {};
      this.page = 1;
      this.pageSize = 20;
    },
    async onConfirmAdd() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          this.submitAdd();
        } else {
          console.log(valid, this.form);
          return false;
        }
      });
    },
    async submitAdd() {
      try {
        const params = {
          txid: this.addForm.txid,
          equity_value: +this.addForm.equity_value,
        };
        await setNFTRights(params);
        this.$message.success("新增成功");
        this.fetchList();
        this.addVisible = false;
      } catch (error) {
        console.log(error);
      }
    },

    async onConfirmUpdate() {
      if (+this.updateForm.equity_value > 0) {
        try {
          const params = {
            txid: this.updateForm.txid,
            equity_value: +this.updateForm.equity_value,
          };
          await setNFTRights(params);
          this.$message.success("修改成功");
          this.fetchList();
          this.updateVisible = false;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$message.error("请输入正确权益值");
      }
    },
    onAdd() {
      this.nftData = null;
      this.addForm = {};
      this.addVisible = true;
    },
    async onQueryNFT(txid) {
      if (!txid) {
        this.$message.error("请先输入TXID");
        return;
      }
      const res = await getNFTInfoByTxid(txid);
      if (res.items && res.items[0]) {
        this.nftData = res.items[0];
      } else {
        this.$message.error("请输入正确的TXID");
      }
    },
  },
};
</script>

<style lang="less">
.add-rights-form {
  .el-form-item__label {
    line-height: 21px;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
}
</style>
