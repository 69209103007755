<template>
  <div>
    <el-form style="margin: 20px 0;" :inline="true" :model="queryForm">
      <el-form-item item label="藏品组名称">
        <el-input v-model="queryForm.managent_group_name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item item :label="aItem.name" v-for="aItem in attributeList" :key="aItem.key">
        <el-select v-model="queryForm[aItem.key]" placeholder="请选择">
          <el-option label="是" :value="1"> </el-option>
          <el-option label="否" :value="0"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button @click="onClear">重置</el-button>
        <el-button type="success" @click="onAdd">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="list" border stripe row-key="id">
      <el-table-column label="藏品组" prop="managent_group_name" align="center"> </el-table-column>
      <el-table-column label="藏品数量" prop="album_count" align="center"> </el-table-column>

      <el-table-column label="是否艺术家藏品" prop="artist_flag" align="center">
        <template slot-scope="scope">
          <attribute-checkbox
            :flag="scope.row.artist_flag"
            v-if="scope.row.managent_group_id != 'all'"
          />
        </template>
      </el-table-column>
      <el-table-column label="是否支持转让" prop="transfer_flag" align="center">
        <template slot-scope="scope">
          <attribute-checkbox
            :flag="scope.row.transfer_flag"
            v-if="scope.row.managent_group_id != 'all'"
          />
        </template>
      </el-table-column>
      <el-table-column label="是否支持上架" prop="shelf_flag" align="center">
        <template slot-scope="scope">
          <attribute-checkbox
            :flag="scope.row.shelf_flag"
            v-if="scope.row.managent_group_id != 'all'"
          />
        </template>
      </el-table-column>
      <el-table-column label="是否支持制作盲盒" prop="create_box_flag" align="center">
        <template slot-scope="scope">
          <attribute-checkbox
            :flag="scope.row.create_box_flag"
            v-if="scope.row.managent_group_id != 'all'"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleClickAddNFTs(scope.row)"
            style="margin: 4px"
            v-if="+scope.row.addable === 1"
          >
            添加
          </el-button>
          <el-button
            size="mini"
            type="info"
            @click="handleClickDetail(scope.row)"
            style="margin: 4px"
          >
            详情
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleClickDelete(scope.row)"
            style="margin: 4px"
            v-if="+scope.row.deletable === 1"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px; text-align: right;">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="新增藏品组"
      :visible.sync="addVisible"
      width="30%"
      @close="addVisible = false"
    >
      <el-form :model="addForm" ref="addForm" :rules="addRules" label-position="top" size="normal">
        <el-form-item label="藏品类型">
          <el-input value="藏品组" disabled></el-input>
        </el-form-item>
        <el-form-item label="组名称" prop="managent_group_name">
          <el-input v-model="addForm.managent_group_name" :maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="属性选择" prop="flags">
          <el-checkbox-group v-model="addForm.flags">
            <el-checkbox
              v-for="item in attributeList"
              :key="item.key"
              :label="item.key"
              name="flag"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onConfirmAdd">确定</el-button>
          <el-button @click="addVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <AttributeAddNFTDialog
      :visible="addNFTVisible"
      @close="handleAddNFTClose"
      @success="handleAddNFTSuccess"
      ref="attributeAddNFTDialogRef"
    />
    <AttributeDetailDialog
      :visible="detailVisible"
      :isAllGroup="isAllGroup"
      @close="handleDetailClose"
      ref="attributeDetailDialogRef"
    />
  </div>
</template>

<script>
import { addNFTGroup, getNFTGroupList, removeNFTGroup } from "@/api/nft-attribute.js";
import AttributeAddNFTDialog from "@/components/AttributeAddNFTDialog.vue";
import AttributeCheckbox from "@/components/AttributeCheckbox.vue";
import AttributeDetailDialog from "@/components/AttributeDetailDialog.vue";

export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],

      attributeList: [
        { name: "是否艺术家藏品", key: "artist_flag" },
        { name: "是否支持转让", key: "transfer_flag" },
        { name: "是否支持上架", key: "shelf_flag" },
        { name: "是否支持制作盲盒", key: "create_box_flag" },
      ],

      queryForm: {},
      currentItem: {},

      // 新增组
      addVisible: false,
      addForm: {
        managent_group_name: "",
        flags: [],
      },
      addRules: {
        managent_group_name: [
          {
            required: true,
            message: "组名称必填，且不超过30个字",
            trigger: "change",
          },
        ],
      },

      // 组下新增NFT弹窗
      addNFTVisible: false,
      // 查看组详情弹窗
      detailVisible: false,
      isAllGroup: false,
    };
  },
  components: {
    AttributeCheckbox,
    AttributeAddNFTDialog,
    AttributeDetailDialog,
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    getPatamsFromQueryForm() {
      let params = {};
      Object.keys(this.queryForm).forEach((key) => {
        if (this.queryForm[key]) {
          params[key] = this.queryForm[key];
        }
      });
      return params;
    },
    async fetchList() {
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
          ...this.getPatamsFromQueryForm(),
        };
        const { data } = await getNFTGroupList(params);
        if (data && data.data) {
          this.list = data.data.items;
          this.total = data.data.total || 0;
        }
      } catch (error) {}
    },

    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchList();
    },
    onQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchList();
    },
    onClear() {
      this.queryForm = {};
      this.page = 1;
      this.pageSize = 20;
    },
    onAdd() {
      this.addVisible = true;
      this.addForm = {
        managent_group_name: "",
        flags: [],
      };
    },
    onConfirmAdd() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          this.submitAdd();
        } else {
          console.log(valid, this.form);
          return false;
        }
      });
    },
    async submitAdd() {
      try {
        const params = {
          managent_group_name: this.addForm.managent_group_name,
          artist_flag: this.addForm.flags.includes("artist_flag") ? 1 : 0,
          transfer_flag: this.addForm.flags.includes("transfer_flag") ? 1 : 0,
          shelf_flag: this.addForm.flags.includes("shelf_flag") ? 1 : 0,
          create_box_flag: this.addForm.flags.includes("create_box_flag") ? 1 : 0,
        };
        await addNFTGroup(params);
        this.$message.success("新增成功");
        this.fetchList();
        this.addVisible = false;
      } catch (error) {
        console.log(error);
      }
    },
    handleAddNFTClose() {
      this.addNFTVisible = false;
    },
    handleAddNFTSuccess() {
      this.fetchList();
      this.addNFTVisible = false;
    },
    handleClickAddNFTs(item) {
      this.currentItem = item;
      this.$refs.attributeAddNFTDialogRef.init(item);
      this.addNFTVisible = true;
    },
    handleDetailClose() {
      this.detailVisible = false;
      this.isAllGroup = false;
    },
    handleClickDetail(item) {
      this.currentItem = item;
      this.$refs.attributeDetailDialogRef.init(item);
      this.isAllGroup = item.managent_group_id == "all";
      this.detailVisible = true;
    },
    handleClickDelete(item) {
      if (+item.album_count > 0) {
        this.$message.error("请先清空组内藏品");
        return;
      }
      this.$confirm(`是否确认删除【${item.managent_group_name}】?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDelete(item);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async handleDelete(item) {
      try {
        const postData = {
          id: item.managent_group_id,
        };
        await removeNFTGroup(postData);
        this.$message.success("删除成功");
        this.fetchList();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
