<template>
  <el-drawer :title="`编辑商品`" :visible="visible" direction="rtl" size="50%" @close="onClose">
    <div class="add-nft-container">
      <div>
        <el-form ref="postForm" :rules="rules" :model="postForm">
          <el-form-item label="藏品名称">
            <div>{{ attributeData.nft_name }}</div>
          </el-form-item>
          <el-form-item label="藏品TXID">
            <div>{{ attributeData.txid }}</div>
          </el-form-item>
          <el-form-item label="铸造者">
            <div>{{ attributeData.creator_name }}</div>
          </el-form-item>
          <el-form-item label="状态">
            <div>{{ attributeData.is_on_shelf ? "已上架" : "未上架" }}</div>
          </el-form-item>
          <el-form-item label="积分兑换值（需用多少积分兑换）" prop="exchange_need_points">
            <el-input
              style="width: 300px;"
              required
              v-model.number="postForm.exchange_need_points"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="快照时间" prop="exchange_snapshot_at">
            <el-date-picker
              v-model="postForm.exchange_snapshot_at"
              type="datetime"
              size="normal"
              placeholder="选择快照时间"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="开启兑换时间" prop="exchange_start_at">
            <el-date-picker
              v-model="postForm.exchange_start_at"
              type="datetime"
              size="normal"
              placeholder="开启兑换时间"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item item label="兑换条件" prop="exchange_snapshot_type">
            <el-select v-model="postForm.exchange_snapshot_type" placeholder="请选择">
              <el-option
                v-for="item in pointOptionList"
                :key="item.key"
                :label="item.name"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button @click="onClose">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import {
  addNFTsToGroup,
  getNFTList,
  editExchangeInfo,
  getExchangeSnapshotTypeList,
} from "@/api/nft-attribute.js";
// import AttributeNFTImage from "@/components/AttributeNFTImage.vue";
// import CopyTxid from "@/components/CopyTxid.vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // CopyTxid,
    // AttributeNFTImage,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      pointOptionList: [],
      multipleSelection: [],
      attributeData: {},
      postForm: {
        exchange_need_points: "",
        exchange_snapshot_at: "",
        exchange_start_at: "",
        exchange_snapshot_type: "",
      },
      rules: {
        exchange_need_points: [
          {
            required: true,
            message: "请输入积分兑换值",
            trigger: "change",
          },
          { type: "number", message: "积分兑换值为数字值", trigger: "change" },
        ],
        exchange_start_at: [{ required: true, message: "请选择开启兑换时间", trigger: "change" }],
        exchange_snapshot_at: [{ required: true, message: "请选择快照时间", trigger: "change" }],
        exchange_snapshot_type: [{ required: true, message: "请选择兑换条件", trigger: "change" }],
      },
    };
  },
  mounted() {},
  methods: {
    init(data) {
      if (data) {
        this.attributeData = data;
        if (data.exchange_nft_info) {
          this.postForm = {
            ...data.exchange_nft_info,
            exchange_snapshot_at: data.exchange_nft_info.exchange_snapshot_at * 1000,
            exchange_start_at: data.exchange_nft_info.exchange_start_at * 1000,
          };
        } else {
          this.postForm = {};
        }
        this.fetchOptionsList();
      }
    },
    async fetchOptionsList() {
      try {
        const { data } = await getExchangeSnapshotTypeList();
        this.pointOptionList = data.data.list;
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      try {
        const params = {
          txid: this.attributeData.txid,
          ...this.postForm,
          exchange_snapshot_at: Math.floor(this.postForm.exchange_snapshot_at / 1000),
          exchange_start_at: Math.floor(this.postForm.exchange_start_at / 1000),
        };
        await editExchangeInfo(params);
        this.$message.success("设置成功");
        this.$emit("success");
      } catch (error) {
        console.log(error);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onSubmit() {
      try {
        this.$refs.postForm.validate((valid) => {
          console.log(valid);
          if (valid) {
            this.submit();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    onClose() {
      this.$emit("close");
    },
    onQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchNFTList();
    },
  },
};
</script>

<style lang="less" scoped>
.add-nft-container {
  position: relative;
  padding: 0 20px 60px 20px;
  height: 100%;

  .add-nft-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ddd;
  }
}
</style>
