<template>
  <div style="padding: 20px;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="藏品" name="first"> <TabAttribute /> </el-tab-pane>
      <el-tab-pane label="权益值加成" name="second"><TabRights /></el-tab-pane>
      <el-tab-pane label="过期时间" name="third"><TabExpired /></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TabAttribute from "./tab-attribute.vue";
import TabExpired from "./tab-expired.vue";
import TabRights from "./tab-rights.vue";
export default {
  components: {
    TabAttribute,
    TabRights,
    TabExpired,
  },
  data() {
    return {
      activeName: "first",
    };
  },
};
</script>

<style lang="less" scoped></style>
